import React, { Component } from "react";

class StepByStep extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            step: 0
        }
    }

    setStep(step) {
        this.setState({
            step: step
        });
    };

    render() {
        return (
            <div className="sbs__wrapper">
                <div className="sbs__question">
                    <h3>Gdzie nastąpiła śmierć?</h3>
                    <ul className="sbs__answers">
                        <li><button onClick={() => this.setStep(1)} className={ this.state.step === 1 ? "selected" : "" }>W domu</button></li>
                        <li><button onClick={() => this.setStep(2)} className={ this.state.step === 2 ? "selected" : "" }>W szpitalu</button></li>
                    </ul>
                </div>
                <div className={ this.state.step === 1 ? "active sbs_information" : "inactive sbs_information" }>
                    <p>
                        W przypadku gdy śmierc nastąpiła w domu z przyczyn naturalnych (np. długotrwała choroba, podeszły wiek, brak w otoczeniu rzeczy które sugerowały by wypadek), należy wezwać pogotowie lub lekarza rodzinnego celem stwierdzenia zgonu.
                        Lekarz który przybędzie na wizytę, jeżeli nie będzie miał żadnych wątpliwości wystawi kartę zgonu, w przypadku podejrzeń wypadku / udziału osób trzecich lub innych wątpliwości, lekarz ma prawo odmówić
                        wypisania karty zgonu i może wezwać policję celem zbadania przyczyn śmierci.
                    </p>
                    <p>
                        Po uzyskaniu karty zgonu można zadzwonic do naszej firmy pod numerem <strong>62 737-10-40 lub +48 797 126 860</strong> celem zlecenia zabrania ciała blizkiej nam osoby do profesjonalej chłodni.
                    </p>
                    <p>
                        <strong>W przypadku gdy lekarz wezwie policję i będą istniały wątpliwości co do przyczyn śmierci</strong>, wówczas to policja wzywa firme pogrzebową, która w danym roku ma podpisaną umowę z prokuraturą na transport zmarłych,
                        <u>wówczas za usługę transportu do chłodni szpitalnej i przechowanie ciała rodzina nie pokrywa kosztów</u>. <strong>Natomiast nadal rodzina ma prawo wyboru dowolnego zakładu pogrzebowego w celu
                        realizacji pogrzebu.</strong>
                    </p>
                    <p>
                        Prokurator, który przybędzie na prośbę policji w przypadku gdy nie stwierdzi się udziału osób trzecich ma prawo odstąpić od dalszego śledztwa i przekazać ciało do "dyspozycji rodziny", 
                        wówczas można zadzwonic do naszej firmy pod numerem <strong>62 737-10-40 lub +48 797 126 860</strong> celem zlecenia zabrania ciała blizkiej nam osoby do profesjonalej chłodni. 
                    </p>
                </div>
                <div className={ this.state.step === 2 ? "active sbs_information" : "inactive sbs_information" }>
                    <p>
                       W przypadku śmierci osoby w szpitalu, należy udać się do szpitala na oddział na którym nastapiła śmierć. Na danym oddziale można uzyskać informacje, a także otrzymać kartę zgonu. 
                    </p>
                </div>
                <div className={ this.state.step > 0 ? "active sbs_information" : "inactive sbs_information" }>
                    <p>
                       Po uzyskaniu karty zgonu można kontynuować organizację pogrzebu kontaktując się z nami telefonicznie pod numerami <strong>62 737-10-40 lub +48 797 126 860</strong> lub poprzez wizytę w naszym biurze przy <strong>ul. Limanowskiego 31 w Ostrowie Wielkopolskim (na przeciwko głównego wjazdu do szpitala i Apteki Bakra)</strong>.
                       Nasi pracownicy przekażą Państwu wówczas żetelne informacje, jakich formalności powinni Państwo dopełnić w następnej kolejności.
                    </p>
                </div>
            </div>
        )
    }
}

export default StepByStep