import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import { graphql } from "gatsby"
import SEO from "../components/Seo"
import SectionTitle from "../components/SectionTitle"
import faq from "../content/porady.json"
import StepByStep from "../components/StepByStep/StepByStep"
import form_zus from "../downloads/form_zus.pdf"
import form_krus from "../downloads/form_krus.pdf"
import upopl from "../downloads/upowaznienie_pl.pdf"
import upoint from "../downloads/upowaznienie_int.pdf"

function PoradyPage(props) {
    const data = props.data;

    return (
        <>
        <SEO title="Porady krok po kroku - jak zorganizować pogrzeb?" />
        <HomeLayout data={data}>
            <div id="porady" className="porady-page">
                <div className="container">
                    <div className="content">
                        <SectionTitle title="Porady" bottom={false} />
                        <p className="text-center">
                            Szanowni Państwo, tak naprawde nie ma uniwersalnej instrukcji krok po kroku jak zorganizować pogrzeb,
                            ponieważ każdy przypadek jest dość mocno indywidualny, poniżej prezentujemy podstawowy schemat postępowania,
                            bez którego nie będą Państwo mogli załatwić dalszych formalności.
                        </p>
                        <p className="text-center">
                            Oczywiście kontakt z naszą firmą może nastąpić na każdym etapie.<br/>
                            Niezależnie od dalszych możliwości ogranizacyjnych zawsze służymy informacjami i radą jakie kroki należy wykonać.
                        </p>
                        <StepByStep/>
                        <SectionTitle title="Formularze i upoważnienia" bottom={false} />
                        <p className="text-center">
                            W celu ułatwienia Państwu załatwienia formalności poniżej znadują się formularze,<br/>zlecenia i wnioski które mogą się
                            przydać Państwu podczas organizacji pogrzebu.
                        </p>
                        <ul className="forms">
                            <li><a href={ form_zus } target="_blank" rel="noopener noreferrer">Wniosek o zasiłek ZUS</a></li>
                            <li><a href={ form_krus } target="_blank" rel="noopener noreferrer">Wniosek o zasiłek KRUS</a></li>
                                <li><a href={upopl}>Upoważnienie Polska</a></li>
                                <li><a href={upoint}>Upoważnienie Międzynarodowe</a></li>
                        </ul>
                        <SectionTitle title="Pytania i odpowiedzi" bottom={false} />
                        {faq.data.map((data, index) => (
                            <div className="porada" key={`content_item_${index}`}>
                                <h2 className="porada__title">{ data.title }</h2>
                                <div className="porada__content">
                                { data.content_html }    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </HomeLayout>
        </>
    )
}
export const pageQuery = graphql`
    query {
        logo: file(relativePath: { eq: "logo_poziome.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
    }
`

export default PoradyPage
